import * as React from 'react';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { Page, PageMeta } from 'components';
import './404.css';

type Props = {
    code: number;
    children: React.ReactNode;
};

// Component is used for passing http status for server side rendering;
// For example, if page isn't found, client give page with 404 status code
const Status = ({ code, children }: Props) => {
    const render = ({ staticContext }: RouteComponentProps) => {
        if (staticContext) {
            staticContext.statusCode = code;
        }

        return children;
    };

    return <Route render={render} />;
};

export default function NotFoundPage() {
    return (
        <Status code={404}>
            <PageMeta
                title="Такой страницы не найдено!"
                description="Telesputnik.ru является одним из самых авторитетных и быстроразвивающихся интернет-порталов в области телевизионной индустрии, рынка OTT, медиапотребления и производства контента."
            />
            <Page>
                {/* <PageContainer> */}
                <div className='not-found'>
                    <div className='not-found__image'>
                        <img src="/img/icons/404.svg" />
                    </div>
                    <h1>Такой страницы не найдено!</h1>
                    <p>Попробуйте вернуться на <Link to={'/'}>главную страницу</Link></p>
                </div>
                {/* </PageContainer> */}
            </Page>
        </Status>
    );
}
